import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { replaceUrlParam } from "../../app/utils";
import StatesDropDown from "../../common/StatesDropDown";
import {
  fetchDistributors,
  getFullList as getFullDistributorsList,
  selectDistributors,
} from "../distributors/distributorsSlice";
import {
  fetchStores,
  getFullList as getFullStoresList,
  selectStores,
} from "../stores/storesSlice";
import {
  fetchManagers,
  fetchMemberGroups,
  fetchMemberTypes,
  fetchReputations,
  selectMembers,
} from "../members/membersSlice";
import { activateUser, updateUser } from "../users/usersSlice";
import { uuidv4 } from "../../app/utils";

const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,

    borderRadius: "24px",
    "&:hover": {
      backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.7,
    },
  },
}))(Button);
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};
const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "45px",
    // backgroundColor: theme.palette.secondary.main,
    width: "100%",
  },
  accountContainer: {
    height: "100%",
    color: theme.palette.primaryFont.main,
  },
  topBackground: {
    // backgroundColor: theme.palette.secondary.main,
    // height: '100%',
  },
  form: {
    // borderBottom: '1px solid rgb(221, 221, 221)',

    marginTop: theme.spacing(1),
    width: "100%", // Fix IE 11 issue.
    color: theme.palette.secondaryFont.main,
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
  },
  registerContainer: {
    borderRadius: 16,
    backgroundColor: "#fff",
    color: theme.palette.secondaryFont.main,
    padding: "50px !important",
  },
  textLabel: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 4,
    marginTop: 8,
  },
  textInput: {},
  userDetailsContainer: {
    padding: 24,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
  },
  userDetails: {
    display: "flex",
    justifyContent: "space-between",
    // color: "#fff",
    fontSize: 40,
  },
  infoContainerEdit: {
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "space-between",
    marginLeft: 80,
  },

  userCardsContainerEdit: {
    display: "flex",
    flexFlow: "row wrap",
    padding: 24,
    boxSizing: "border-box",
    justifyContent: "space-between",
    flexGrow: 1,
    width: "100%",
  },
  historyCardContainerEdit: {
    display: "flex",
    flexGrow: 1,
    marginRight: 80,

    // padding: 24,
    // boxSizing: 'border-box',
  },
  historyCardEdit: {
    // display: 'flex',
    flexGrow: 1,
    padding: 24,
    borderRadius: 16,
  },
  cardHeader: {
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
    fontSize: 40,
  },
  formControl: {
    borderWidth: 2,
    borderStyle: "solid",
    borderColor: theme.palette.primaryFont.main,
    borderRadius: 16,
    display: "flex",
    marginTop: 12,
  },
}));

const usePlaceholderStyles = makeStyles((theme) => ({
  placeholder: {
    color: theme.palette.primaryFont.main,
  },
}));

const Placeholder = ({ children }) => {
  const classes = usePlaceholderStyles();
  return <div className={classes.placeholder}>{children}</div>;
};

export default function MemberDetails(props) {
  const { userAccount, user } = props;
  const classes = useStyles();
  let jwtToken = localStorage.getItem("clientToken");

  const { distributorsList, distributorsAmount } =
    useSelector(selectDistributors);
  const { storesList, storesAmount } = useSelector(selectStores);
  const { memberTypesList, memberGroupsList, reputationList, managersList } =
    useSelector(selectMembers);

  const firstUpdateDist = useRef(true);
  const firstUpdateStore = useRef(true);

  const dispatch = useDispatch();
  // const {users, user, user, signedIn} = useSelector(selectUsers);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [poBox, setPoBox] = useState("");
  const [userCode, setUserCode] = useState("");
  const [company, setCompany] = useState("");
  // const [streetNumber, setStreetNumber] = useState("");
  const [streetName, setStreetName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [suburb, setSuburb] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [statusList, setStatusList] = useState([
    { id: 1, value: "Active", name: "Active" },
    { id: 2, value: "Inactive", name: "Inactive" },
    { id: 5, value: "Pending", name: "Pending" },
  ]);

  const [distributor, setDistributor] = useState(null);
  const [store, setStore] = useState(null);
  const [memberGroup, setMemberGroup] = useState(null);
  const [memberType, setMemberType] = useState(null);
  const [reputation, setReputation] = useState(null);
  const [manager, setManager] = useState(null);

  const [statusOpen, setStatusOpen] = useState(false);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(true);

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleStatusClose = () => {
    setStatusOpen(false);
  };

  const handleStatusOpen = () => {
    setStatusOpen(true);
  };

  useEffect(() => {
    if (!distributorsList.length) {
      dispatch(
        fetchDistributors({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!storesList.length) {
      dispatch(
        fetchStores({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!memberTypesList.length) {
      dispatch(
        fetchMemberTypes({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!memberGroupsList.length) {
      dispatch(
        fetchMemberGroups({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
    if (!managersList.length) {
      dispatch(
        fetchManagers({
          token: jwtToken,
          query: `?role_id=4`,
        })
      );
    }
    if (
      !reputationList.length &&
      process.env.REACT_APP_ENABLE_REPUTATION == "true"
    ) {
      dispatch(
        fetchReputations({
          token: jwtToken,
          query: `?limit=100`,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (store) {
      dispatch(
        fetchManagers({
          token: jwtToken,
          query: `?role_id=4&store_id=${store.id}`,
        })
      );
    }
  }, [store]);

  useEffect(() => {
    if (
      memberTypesList.length > 0 &&
      (!memberType || memberType === undefined)
    ) {
      setMemberType(memberTypesList.find((mt) => mt.id === user.memberTypeID));
    }
  }, [memberTypesList, user]);
  useEffect(() => {
    if (
      memberGroupsList.length > 0 &&
      (!memberGroup || memberGroup === undefined)
    ) {
      setMemberGroup(
        memberGroupsList.find((mt) => mt.id === user.memberGroupID)
      );
    }
  }, [memberGroupsList, user]);
  useEffect(() => {
    if (
      reputationList.length > 0 &&
      (!reputation || reputation === undefined)
    ) {
      setReputation(reputationList.find((mt) => mt.id === user.reputationID));
    }
  }, [reputationList, user]);
  useEffect(() => {
    if (
      distributorsList.length > 0 &&
      (!distributor || distributor === undefined)
    ) {
      setDistributor(
        distributorsList.find((mt) => mt.id === user.distributorID)
      );
    }
  }, [distributorsList, user]);
  useEffect(() => {
    if (storesList.length > 0 && (!store || store === undefined)) {
      setStore(storesList.find((mt) => mt.id === user.storeID));
    }
  }, [storesList, user]);

  useEffect(() => {
    if (
      distributorsList.length &&
      distributorsList.length != distributorsAmount &&
      firstUpdateDist.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullDistributorsList({
          token: jwtToken,
          offset: distributorsList.length,
          query: searchQuery,
          targetLength: distributorsAmount,
        })
      ).then(() => {
        setDistributor(
          distributorsList.find((dist) => dist.id === user.distributorID)
        );
      });
      firstUpdateDist.current = false;
    }
  }, [distributorsList.length]);

  useEffect(() => {
    if (
      storesList.length &&
      storesList.length != storesAmount &&
      firstUpdateStore.current
    ) {
      let searchQuery = "?limit=100";
      searchQuery = replaceUrlParam(searchQuery, "limit", 100);
      dispatch(
        getFullStoresList({
          token: jwtToken,
          offset: storesList.length,
          query: searchQuery,
          targetLength: storesAmount,
        })
      ).then(() => {
        setStore(storesList.find((store) => store.id === user.storeID));
      });
      firstUpdateStore.current = false;
    }
  }, [storesList.length]);

  useEffect(() => {
    setUsername(user.username);
    setStatus(user.statusID);
    setFirstName(user.firstName);
    setLastName(user.lastName);
    setDisplayName(user.displayName);
    setEmail(user.email);
    setMobile(user.mobile);
    setUserCode(user.externalID);
    setCompany(user.company);
    setManager({
      id: user.directManagerID,
      displayName: user.directManagerName,
    });

    if (user.postalAddress) {
      setStreetName(user.postalAddress.streetName);
      setCompanyName(user.postalAddress.companyName);
      setSuburb(user.postalAddress.suburb);
      setPostCode(user.postalAddress.postCode);
      setCity(user.postalAddress.city);
      setState(user.postalAddress.state.toUpperCase());
      setCountry(user.postalAddress.country);
    }
  }, [user]);

  const handleEditSubmit = (event) => {
    event.preventDefault();
    const userDetails = {
      clientID: Number(process.env.REACT_APP_CLIENT_ID),
      username: username,
      firstName: firstName,
      lastName: lastName,
      displayName: firstName + " " + lastName,
      email: email,
      mobile:
        mobile == ""
          ? uuidv4()
          : mobile.startsWith("+")
          ? mobile
          : country == "Australia"
          ? "+61" + mobile.replace(/^0+/, "")
          : "+64" + mobile.replace(/^0+/, ""),
      company: company,
      externalID: userCode,
      postalAddress: {
        // streetNumber: streetNumber,
        companyName: companyName,
        streetName: streetName,
        suburb: suburb,
        postCode: postCode,
        city: city,
        state: state,
        country: country,
      },
    };
    if (distributor) {
      userDetails["distributorID"] = distributor.id;
    }
    if (store) {
      userDetails["storeID"] = store.id;
    }
    if (memberGroup) {
      userDetails["memberGroupID"] = memberGroup.id;
    }
    if (memberType) {
      userDetails["memberTypeID"] = memberType.id;
    }
    if (reputation) {
      userDetails["reputationID"] = reputation.id;
    }
    if (manager) {
      userDetails["managerID"] = manager.id;
    }

    if (user.statusID == 5 && status == 1) {
      dispatch(
        activateUser({
          token: jwtToken,
          id: user.id,
        })
      ).then(() => {
        dispatch(
          updateUser({ token: jwtToken, id: user.id, user: userDetails })
        );
      });
    } else {
      userDetails["statusID"] = status;
      dispatch(updateUser({ token: jwtToken, id: user.id, user: userDetails }));
    }
  };

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  console.log("user role:", user.roleID);
  console.log("user acc role:", userAccount.roleID);

  return (
    <Grid className={classes.historyCardEdit}>
      <form className={classes.form} onSubmit={handleEditSubmit}>
        <div className={classes.userDetails}>
          <div>
            <div
              style={{
                color: "theme.palette.secondary.main;",
                fontWeight: "bold",
                marginBottom: 12,
              }}
            >
              {process.env.REACT_APP_ACC_COMPANY_NAME == "true"
                ? user.company && user.company + " Account"
                : user.displayName && user.displayName + "'s Account"}
            </div>
            <div
              style={{
                color: "theme.palette.secondary.main;",
                fontSize: 24,
                fontWeight: "bold",
              }}
            >
              Member Since:{" "}
              {user.createdAt &&
                user.createdAt.substring(0, 10).split("-").reverse().join("/")}
            </div>
          </div>
          <div className={classes.actionsItem}>
            <div
              style={{
                fontSize: 24,
              }}
            >
              Set Member Status
            </div>
            <FormControl className={classes.formControl}>
              <Select
                labelId="store-select-label"
                id="store-select"
                open={statusOpen}
                onClose={handleStatusClose}
                onOpen={handleStatusOpen}
                value={status}
                displayEmpty
                onChange={handleStatusChange}
                IconComponent={iconComponent}
                classes={{ root: classes.select }}
                input={<Input className={classes.storeSelect} />}
                // input={<Input className={classes.brandSelect} />}
                // renderValue={(selected) => selected.join(', ')}
                // renderValue={(selected) => sortCategory}
                MenuProps={MenuProps}
                // displayEmpty
              >
                {statusList.map((status) => (
                  <MenuItem
                    // style={{color: theme.palette.primary.main}}
                    value={status.id}
                  >
                    {status.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div className={classes.textLabel}>First Name: </div>
        <TextField
          defaultValue={user.firstName}
          key={user.firstName}
          className={classes.textInput}
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="firstName"
          name="firstName"
          autoComplete="firstName"
          onChange={(e) => setFirstName(e.target.value)}
        />
        <div className={classes.textLabel}>Surname: </div>
        <TextField
          defaultValue={user.lastName}
          key={user.lastName}
          className={classes.textInput}
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="lastName"
          name="lastName"
          autoComplete="lastName"
          onChange={(e) => setLastName(e.target.value)}
        />
        <div className={classes.textLabel}>Username: </div>
        <TextField
          defaultValue={user.username}
          key={user.username}
          className={classes.textInput}
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="username"
          name="username"
          autoComplete="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <div className={classes.textLabel}>Email: </div>
        <TextField
          defaultValue={user.email}
          key={user.email}
          className={classes.textInput}
          variant="outlined"
          margin="dense"
          required
          fullWidth
          id="email"
          name="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div className={classes.textLabel}>Mobile: </div>
        <div className={classes.textInput}>
          <TextField
            defaultValue={user.mobile}
            key={user.mobile}
            className={classes.textInput}
            variant="outlined"
            margin="dense"
            required={process.env.REACT_APP_MOBILE_UNREQUIRED != "true"}
            fullWidth
            id="mobile"
            name="mobile"
            autoComplete="mobile"
            onChange={(e) => setMobile(e.target.value)}
          />
        </div>
        <div className={classes.textLabel}>Organisation/Company Name: </div>
        <TextField
          defaultValue={user.company}
          key={user.company}
          className={classes.textInput}
          variant="outlined"
          margin="dense"
          fullWidth
          id="company"
          name="company"
          autoComplete="company"
          onChange={(e) => setCompany(e.target.value)}
        />
        <div className={classes.textLabel}>
          {process.env.REACT_APP_CLIENT_ID == "17"
            ? "ACD Trade Account Number"
            : process.env.REACT_APP_CLIENT_ID == "19"
            ? "CAS Trade Account Number"
            : "User Code"}
          {process.env.REACT_APP_OKI_USER_CODE == "true" &&
            process.env.REACT_APP_CLIENT_ID !== "17" &&
            process.env.REACT_APP_CLIENT_ID !== "19" && (
              <span> (ABN/NZBN)</span>
            )}
          :{" "}
        </div>
        <TextField
          defaultValue={user.externalID}
          key={user.externalID}
          className={classes.textInput}
          InputProps={{
            readOnly:
              process.env.REACT_APP_CLIENT_ID != "17" &&
              userAccount.roleID != 1,
          }}
          variant="outlined"
          margin="dense"
          fullWidth
          id="userCode"
          name="userCode"
          autoComplete="userCode"
          onChange={(e) => setUserCode(e.target.value)}
        />{" "}
        {distributorsList.length > 0 && (
          <div>
            <div className={classes.textLabel}>Distributor</div>
            <Autocomplete
              onChange={(event, distributor) => {
                setDistributor(distributor);
              }}
              id={`combo-box-distributor`}
              options={distributorsList}
              getOptionSelected={(option, value) => option.id == value}
              getOptionLabel={(option) => option.name}
              value={distributor}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
        )}
        {storesList.length > 0 && (
          <div>
            <div className={classes.textLabel}>
              {process.env.REACT_APP_ACD_BRANCH == "true" ? "Branch" : "Store"}
            </div>
            <Autocomplete
              onChange={(event, store) => {
                setStore(store);
              }}
              id={`combo-box-store`}
              options={storesList}
              getOptionSelected={(option, value) => option.id == value}
              getOptionLabel={(option) => option.name}
              value={store}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  margin="dense"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </div>
        )}
        {memberGroupsList.length > 0 &&
          process.env.REACT_APP_ACD_BRANCH != "true" && (
            <div>
              <div className={classes.textLabel}>Member Group</div>
              <Autocomplete
                onChange={(event, group) => {
                  setMemberGroup(group);
                }}
                id={`combo-box-store`}
                options={
                  process.env.REACT_APP_REFERRAL == "true" && store
                    ? memberGroupsList.filter(
                        (group) => group.storeID == store.id
                      )
                    : memberGroupsList
                }
                getOptionSelected={(option, value) => option.id == value}
                getOptionLabel={(option) => option.name}
                value={memberGroup}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          )}
        {managersList.length > 0 &&
          process.env.REACT_APP_EDIT_DIRECT_MANAGER == "true" && (
            <div>
              <div className={classes.textLabel}>
                {process.env.REACT_APP_ACD_BRANCH != "true"
                  ? "Supervisor"
                  : "Branch Manager"}
              </div>
              <Autocomplete
                onChange={(event, group) => {
                  setManager(group);
                }}
                id={`combo-box-store`}
                options={managersList}
                getOptionSelected={(option, value) => option.id == value}
                getOptionLabel={(option) => option.displayName}
                value={manager}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          )}
        {memberTypesList.length > 0 &&
          process.env.REACT_APP_ACD_BRANCH != "true" && (
            <div>
              <div className={classes.textLabel}>Member Type</div>
              <Autocomplete
                onChange={(event, type) => {
                  setMemberType(type);
                }}
                id={`combo-box-store`}
                options={memberTypesList}
                getOptionSelected={(option, value) => option.id == value}
                getOptionLabel={(option) => option.name}
                value={memberType}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          )}
        {reputationList.length > 0 &&
          process.env.REACT_APP_ACD_BRANCH != "true" && (
            <div>
              <div className={classes.textLabel}>Reputation</div>
              <Autocomplete
                onChange={(event, rep) => {
                  setReputation(rep);
                }}
                id={`combo-box-store`}
                options={reputationList}
                getOptionSelected={(option, value) => option.id == value}
                getOptionLabel={(option) => option.name}
                value={reputation}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    margin="dense"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            </div>
          )}
        <div
          style={{
            fontSize: 40,
            fontWeight: "bold",
            marginTop: 24,
          }}
        >
          Delivery Address
        </div>
        <Grid container direction="column">
          <Grid item sm>
            <div className={classes.textLabel}>
              Company Name <span style={{ fontSize: 12 }}>(if applicable)</span>
              :{" "}
            </div>
            <TextField
              defaultValue={
                user.postalAddress ? user.postalAddress.companyName : ""
              }
              key={user.postalAddress ? user.postalAddress.companyName : ""}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="company-number-street"
              type="text"
              fullWidth
              onChange={(e) => setCompanyName(e.target.value)}
            />
          </Grid>
          <Grid item sm>
            <div className={classes.textLabel}>Street Address: </div>
            <TextField
              defaultValue={
                user.postalAddress ? user.postalAddress.streetName : ""
              }
              key={user.postalAddress ? user.postalAddress.streetName : ""}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="street-number-street"
              type="text"
              fullWidth
              onChange={(e) => setStreetName(e.target.value)}
            />
          </Grid>
          {/* <Grid item sm>
                <Grid container direction="row" spacing={2}> */}
          <Grid item sm>
            <div className={classes.textLabel}>Suburb: </div>
            <TextField
              defaultValue={user.postalAddress ? user.postalAddress.suburb : ""}
              key={user.postalAddress ? user.postalAddress.suburb : ""}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="subarb-street"
              type="text"
              fullWidth
              onChange={(e) => setSuburb(e.target.value)}
            />
          </Grid>
          <Grid item sm>
            <div className={classes.textLabel}>Post Code: </div>
            <TextField
              defaultValue={
                user.postalAddress ? user.postalAddress.postCode : ""
              }
              key={user.postalAddress ? user.postalAddress.postCode : ""}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="post-code-street"
              type="text"
              inputProps={{ maxLength: 4 }}
              fullWidth
              onChange={(e) => setPostCode(e.target.value)}
            />
          </Grid>
          {/* </Grid>
              </Grid> */}
          {/* <Grid item sm>
                <Grid container direction="row" spacing={2}> */}
          {process.env.REACT_APP_CLIENT_ID != "11" &&
            process.env.REACT_APP_CLIENT_ID != "12" && (
              <Grid item sm>
                <div className={classes.textLabel}>City: </div>
                <TextField
                  defaultValue={
                    user.postalAddress ? user.postalAddress.city : ""
                  }
                  key={user.postalAddress ? user.postalAddress.city : ""}
                  className={classes.textItem}
                  variant="outlined"
                  margin="dense"
                  id="city-street"
                  type="text"
                  fullWidth
                  onChange={(e) => setCity(e.target.value)}
                />
              </Grid>
            )}
          <Grid item sm>
            <div className={classes.textLabel}>State/Territory: </div>
            {/* <TextField
              defaultValue={
                user.postalAddress ? user.postalAddress.state : ""
              }
              key={
                user.postalAddress ? user.postalAddress.state : ""
              }
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="state-street"
              type="text"
              fullWidth
              onChange={(e) => setState(e.target.value)}
            /> */}
            <StatesDropDown
              placeholder={"State/Territory"}
              setSelectedState={setState}
              state={state}
            />
          </Grid>
          {/* </Grid>
              </Grid> */}
          <Grid item sm>
            <div className={classes.textLabel}>Country: </div>
            <TextField
              defaultValue={
                user.postalAddress ? user.postalAddress.country : ""
              }
              key={user.postalAddress ? user.postalAddress.country : ""}
              className={classes.textItem}
              variant="outlined"
              margin="dense"
              id="country-street"
              type="text"
              fullWidth
              onChange={(e) => setCountry(e.target.value)}
            />
          </Grid>
        </Grid>
        {((user.roleID == 1 && userAccount.roleID == 1) ||
          (user.roleID >= 2 && userAccount.roleID <= 2)) && (
          <ColorButton type="submit" fullWidth variant="contained">
            Save Changes
          </ColorButton>
        )}
      </form>
    </Grid>
  );
}
