import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import useMediaQuery from "@material-ui/core/useMediaQuery";

import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";

import { selectUsers, fetchUserByID } from "../users/usersSlice";
import { selectOrders, updateOrderDetails } from "./ordersSlice";
const ColorButton = withStyles((theme) => ({
  root: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    color: theme.palette.secondaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.secondaryButton.main,
      opacity: 0.7,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const SecondaryButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.primaryButton.main,
    color: "#fff",
    width: 250,
    fontWeight: "bold",
    borderRadius: 16,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
    },
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  orderContainer: {
    // margin: 60,
    // minWidth: 1280,
    width: "95%",
  },
  cartTitle: {
    textAlign: "left",
    marginTop: 60,
    marginBottom: 24,
    fontWeight: "bold",
    fontSize: 40,
  },
  cartProducts: {
    // margin: 60
    borderRadius: 16,
    padding: 32,
    marginBottom: 60,
    color: theme.palette.secondaryFont.main,
    fontSize: 16,
    fontWeight: "bold",
  },
  orderNumber: {
    color: theme.palette.primaryFont.main,
  },
  productsContainer: { marginTop: 16, paddingLeft: 8, paddignRight: 8 },
  productsHeader: {
    // marginRight: 50,
    paddingBottom: 0,
    // padding: 0,
    display: "flex",
    alignItems: "center",
    backgroundColor: "#ddd",
  },
  productsList: {
    borderBottom: "1px solid #DDD",
    paddingBottom: 16,
    paddingTop: 16,
  },
  productsActions: {
    paddingTop: 8,
  },
  cartDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  deliveryDetailsContainer: { width: "75%", marginRight: 48 },
  deliveryDetails: {
    padding: 32,
    paddingBottom: 24,
    paddingTop: 24,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    borderRadius: 16,
  },
  additionalPapersContainer: {
    display: "flex",
    flexDirection: "column",
  },
  comments: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  assistance: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
    textAlign: "center",
  },
  assistanceText: {
    fontWeight: "bold",
    marginBottom: 8,
  },
  review: {
    borderRadius: 16,
    padding: 32,
    paddingBottom: 56,
    paddingTop: 48,
    marginBottom: 82,
    color: theme.palette.secondaryFont.main,
    fontSize: 24,
  },
  textLabel: {
    marginBottom: 4,
    // marginTop: 16,
  },
  header: {
    color: theme.palette.primaryFont.main,
  },
}));

export default function Order() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let jwtToken = localStorage.getItem("clientToken");
  const { order } = useSelector(selectOrders);
  const { userAccount, user } = useSelector(selectUsers);
  const [loaded, setLoaded] = useState(true);
  const matches = useMediaQuery("(min-width:850px)");

  const adminEdit = process.env.REACT_APP_ENABLE_EDIT_CLIENT_ORDERS == "true";

  const [editOrder, setEditOrder] = useState(adminEdit && order);

  const updateOrder = () => {
    dispatch(
      updateOrderDetails({
        token: jwtToken,
        order: editOrder,
        orderID: order.id,
      })
    ).then(() => {
      history.push(`/admin/orders`);
    });
  };

  // useEffect(() => {
  //   dispatch(fetchMyself({ token: jwtToken }));
  // }, []);

  // useEffect(() => {
  //   if (!order.id) {
  //     let orderID = location.pathname.split("/")[3];
  //     dispatch(
  //       fetchOrderByID({
  //         token: jwtToken,
  //         clientID: userAccount.clientID,
  //         orderID,
  //       })
  //     );
  //     setLoaded(true);
  //   } else {
  //     setLoaded(true);
  //   }
  // }, []);

  // useEffect(() => {
  //   let orderID = location.pathname.split("/")[3];
  //   dispatch(
  //     fetchOrderByID({
  //       token: jwtToken,
  //       clientID: userAccount.clientID,
  //       orderID,
  //     })
  //   );
  // }, []);

  useEffect(() => {
    if (order.userID) {
      dispatch(
        fetchUserByID({
          token: jwtToken,
          id: order.userID,
        })
      );
    }
  }, []);

  if (order && !order.id) {
    history.push(`/admin/orders`);
  }

  const formatPTS = (str) => {
    return str.toLocaleString();
  };
  //

  const statusList = [
    { id: 1, name: "active" },
    { id: 2, name: "inactive" },
    { id: 3, name: "unavailable" },
    { id: 4, name: "deleted" },
    { id: 5, name: "pending" },
    { id: 6, name: "confirmed" },
    { id: 7, name: "cancelled" },
    { id: 8, name: "outstanding" },
    { id: 9, name: "completed" },
    { id: 10, name: "done" },
    { id: 11, name: "paid" },
    { id: 12, name: "sent" },
    { id: 16, name: "dispatched" },
    { id: 21, name: "declined" },
    { id: 23, name: "refunded" },
    { id: 24, name: "processing" },
    { id: 25, name: "invoiced" },
    { id: 26, name: "purchaseordered" },
    { id: 27, name: "approved" },
    { id: 28, name: "purchased" },
  ];

  const getStatusName = (id) => {
    let status = statusList.filter((stat) => stat.id === id);
    return status.length ? status[0].name : "";
  };

  let content = <div></div>;
  if (loaded) {
    content = (
      <Paper elevation={3} className={classes.cartProducts}>
        <div
          style={{
            fontSize: 40,
            fontWeight: "bold",
            marginBottom: 24,
          }}
          className={classes.orderNumber}
        >
          Order Number: {order.id}
        </div>
        <div
          style={{
            display: "flex",
            flexFlow: "row nowrap",
          }}
        >
          <div style={{ marginRight: 60 }}>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginBottom: 16,
              }}
              className={classes.header}
            >
              Member Info
            </div>
            <div className={classes.textLabel}>
              Member Display Name:{" "}
              <span style={{ fontWeight: "normal" }}>
                {adminEdit ? (
                  <TextField
                    defaultValue={order.userDisplayName}
                    key={userAccount.userDisplayName}
                    className={classes.textInput}
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="displayName"
                    name="displayName"
                    autoComplete="displayName"
                    onChange={(e) =>
                      setEditOrder((order) => {
                        return { ...order, userDisplayName: e.target.value };
                      })
                    }
                  />
                ) : (
                  order.userDisplayName
                )}
              </span>
            </div>
            <div className={classes.textLabel}>
              Email:{" "}
              <span style={{ fontWeight: "normal" }}>
                {" "}
                {adminEdit ? (
                  <TextField
                    defaultValue={order.userEmail}
                    key={userAccount.userEmail}
                    className={classes.textInput}
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="displayName"
                    name="displayName"
                    autoComplete="displayName"
                    onChange={(e) =>
                      setEditOrder((order) => {
                        return { ...order, userEmail: e.target.value };
                      })
                    }
                  />
                ) : (
                  order.userEmail
                )}
              </span>
            </div>
            <div className={classes.textLabel}>
              Contact Number:{" "}
              <span style={{ fontWeight: "normal" }}>
                {" "}
                {adminEdit ? (
                  <TextField
                    defaultValue={order.userMobile}
                    key={userAccount.userMobile}
                    className={classes.textInput}
                    variant="outlined"
                    margin="dense"
                    required
                    fullWidth
                    id="displayName"
                    name="displayName"
                    autoComplete="displayName"
                    onChange={(e) =>
                      setEditOrder((order) => {
                        return { ...order, userMobile: e.target.value };
                      })
                    }
                  />
                ) : (
                  order.userMobile
                )}
              </span>
            </div>
            {(process.env.REACT_APP_CLIENT_ID == "11" ||
              process.env.REACT_APP_CLIENT_ID == "12") && (
              <div className={classes.textLabel}>
                User Code:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.externalID ? user.externalID : "N/A"}
                </span>
              </div>
            )}
            {(process.env.REACT_APP_CLIENT_ID == "11" ||
              process.env.REACT_APP_CLIENT_ID == "12") && (
              <div className={classes.textLabel}>
                Company/Organisation Name:{" "}
                <span style={{ fontWeight: "normal" }}>
                  {user.company ? user.company : "N/A"}
                </span>
              </div>
            )}
          </div>
          <div>
            <div
              style={{
                fontSize: 24,
                fontWeight: "bold",
                marginBottom: 16,
              }}
              className={classes.header}
            >
              Delivery Address
            </div>
            <Grid container direction="column" style={{ minWidth: 400 }}>
              {(process.env.REACT_APP_CLIENT_ID == "11" ||
                process.env.REACT_APP_CLIENT_ID == "12") && (
                <Grid item sm>
                  <div className={classes.textLabel}>
                    Company Name:{" "}
                    {order.deliveryAddress
                      ? order.deliveryAddress.companyName
                        ? order.deliveryAddress.companyName
                        : "N/A"
                      : "N/A"}
                  </div>
                </Grid>
              )}
              <Grid item sm>
                <div className={classes.textLabel}>
                  Street Name:{" "}
                  {adminEdit ? (
                    <TextField
                      defaultValue={order.deliveryAddress.streetName}
                      key={order.deliveryAddress.streetName}
                      className={classes.textInput}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="displayName"
                      name="displayName"
                      autoComplete="displayName"
                      onChange={(e) =>
                        setEditOrder((order) => {
                          return {
                            ...order,
                            deliveryAddress: {
                              ...order.deliveryAddress,
                              streetName: e.target.value,
                            },
                          };
                        })
                      }
                    />
                  ) : order.deliveryAddress ? (
                    order.deliveryAddress.streetName
                  ) : (
                    ""
                  )}
                </div>
              </Grid>

              <Grid item sm>
                <div className={classes.textLabel}>
                  Suburb:{" "}
                  {adminEdit ? (
                    <TextField
                      defaultValue={order.deliveryAddress.suburb}
                      key={order.deliveryAddress.suburb}
                      className={classes.textInput}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="displayName"
                      name="displayName"
                      autoComplete="displayName"
                      onChange={(e) =>
                        setEditOrder((order) => {
                          return {
                            ...order,
                            deliveryAddress: {
                              ...order.deliveryAddress,
                              suburb: e.target.value,
                            },
                          };
                        })
                      }
                    />
                  ) : order.deliveryAddress ? (
                    order.deliveryAddress.suburb
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
              {(process.env.REACT_APP_CLIENT_ID != "11" && process.env.REACT_APP_CLIENT_ID != "12")
                && (
              <Grid item sm>
                <div className={classes.textLabel}>
                  City:{" "}
                  {adminEdit ? (
                    <TextField
                      defaultValue={order.deliveryAddress.city}
                      key={order.deliveryAddress.city}
                      className={classes.textInput}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="displayName"
                      name="displayName"
                      autoComplete="displayName"
                      onChange={(e) =>
                        setEditOrder((order) => {
                          return {
                            ...order,
                            deliveryAddress: {
                              ...order.deliveryAddress,
                              city: e.target.value,
                            },
                          };
                        })
                      }
                    />
                  ) : order.deliveryAddress ? (
                    order.deliveryAddress.city
                  ) : (
                    ""
                  )}
                </div>
              </Grid>)}
              <Grid item sm>
                <div className={classes.textLabel}>
                  Postcode:
                  {adminEdit ? (
                    <TextField
                      defaultValue={order.deliveryAddress.postCode}
                      key={order.deliveryAddress.postCode}
                      className={classes.textInput}
                      variant="outlined"
                      margin="dense"
                      required
                      fullWidth
                      id="displayName"
                      name="displayName"
                      autoComplete="displayName"
                      onChange={(e) =>
                        setEditOrder((order) => {
                          return {
                            ...order,
                            deliveryAddress: {
                              ...order.deliveryAddress,
                              postCode: e.target.value,
                            },
                          };
                        })
                      }
                    />
                  ) : order.deliveryAddress ? (
                    order.deliveryAddress.postCode
                  ) : (
                    ""
                  )}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
        <Grid
          container
          spacing={3}
          direction="column"
          className={classes.productsContainer}
        >
          <Grid
            item
            sm
            style={{ paddingTop: 0, paddingBottom: 0, marginTop: 24 }}
          >
            <Grid
              container
              spacing={3}
              direction="row"
              className={classes.productsHeader}
            >
              <Grid item sm style={{ minWidth: !matches && 200 }}>
                Product:
              </Grid>
              <Grid item sm style={{ minWidth: !matches && 200 }}>
                SKU:
              </Grid>
              <Grid item sm style={{ minWidth: !matches && 200 }}>
                Quantity:
              </Grid>
              <Grid item sm style={{ minWidth: !matches && 200 }}>
                Price (pts)
              </Grid>
              <Grid item sm style={{ minWidth: !matches && 200 }}>
                Status:
              </Grid>
              {!adminEdit && (
                <Grid item sm style={{ minWidth: !matches && 200 }}>
                  Tracking URL:
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item sm>
            {order.products &&
              order.products.length &&
              order.products.map((pr, index) => (
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  alignItems="center"
                  className={classes.productsList}
                >
                  <Grid item sm style={{ minWidth: !matches && 200 }}>
                    {pr.productName}
                  </Grid>
                  <Grid item sm style={{ minWidth: !matches && 200 }}>
                    {pr.productSKU}
                  </Grid>
                  <Grid item sm style={{ minWidth: !matches && 200 }}>
                    {pr.quantity}
                  </Grid>
                  <Grid item sm style={{ minWidth: !matches && 200 }}>
                    {pr.totalPoints.toFixed(0)}
                  </Grid>
                  <Grid item sm style={{ minWidth: !matches && 200 }}>
                    {adminEdit ? (
                      <select
                        defaultValue={order.statusID}
                        isDisabled={(e) => e.target.isDisabled}
                        onChange={(e) =>
                          setEditOrder((order) => {
                            return {
                              ...order,
                              statusID: Number(e.target.value),
                            };
                          })
                        }
                      >
                        {[
                          {
                            id: 5,
                            value: "Pending",
                            name: "Pending",
                            isDisabled: true,
                          },
                          { id: 16, value: "Dispatched", name: "Dispatched" },
                          { id: 23, value: "Refunded", name: "Refunded" },
                          {
                            id: 24,
                            value: "Processing",
                            name: "Processing",
                            isDisabled: adminEdit ? false : true,
                          },
                          { id: 9, value: "Completed", name: "Completed" },
                        ].map((status) => (
                          <option
                            value={status.id}
                            disabled={status.isDisabled}
                          >
                            {status.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      getStatusName(pr.statusID)
                    )}
                  </Grid>
                  {!adminEdit && (
                    <Grid item sm style={{ minWidth: !matches && 200 }}>
                      {pr.courierTrackingURL && (
                        <Link
                          to={{ pathname: pr.courierTrackingURL }}
                          target="_blank"
                        >
                          View Tracking
                        </Link>
                      )}
                    </Grid>
                  )}
                </Grid>
              ))}
          </Grid>
          <div
            style={{
              fontSize: 24,
              fontWeight: "bold",
              marginBottom: 16,
            }}
            className={classes.header}
          >
            Order Notes
          </div>

          {adminEdit ? (
            <TextField
              id="standard-multiline-static"
              // label="Multiline"
              fullWidth
              style={{
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: 4,
                width: "100%",
                marginRight: 24,
              }}
              multiline
              rows={4}
              onChange={(e) =>
                setEditOrder((order) => {
                  return { ...order, notes: e.target.value };
                })
              }
              defaultValue={order.notes}
            />
          ) : (
            <span>{order.notes}</span>
          )}
          <Grid item sm style={{ maxHeight: 90 }}>
            <Grid
              container
              spacing={3}
              direction="row"
              className={classes.productsActions}
            >
              {adminEdit && (
                <Grid item sm>
                  <SecondaryButton onClick={updateOrder}>
                    Update Order
                  </SecondaryButton>
                </Grid>
              )}
              <Grid item sm style={{ textAlign: "right" }}>
                <div>
                  Subtotal:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {order.totalPoints && order.totalPoints.toLocaleString()}{" "}
                    pts
                  </span>
                </div>
                <div style={{ marginTop: 8 }}>
                  Total:{" "}
                  <span style={{ fontWeight: "normal" }}>
                    {order.totalPoints && order.totalPoints.toLocaleString()}{" "}
                    pts
                  </span>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return <div className={classes.orderContainer}>{content}</div>;
}
