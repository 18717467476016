import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  preSignedURLcontents,
  selectContents
} from "../content/contentsSlice";
import { selectUsers } from "../users/usersSlice";
import { selectCustomisation, setCustomisation } from "./customisationSlice";

const ColorButton = withStyles((theme) => ({
  root: {
    // margin: theme.spacing(3, 0, 2),
    margin: theme.spacing(0, 0, 0),
    backgroundColor: theme.palette.primaryButton.main,
    color: theme.palette.primaryButtonFont.main,
    "&:hover": {
      backgroundColor: theme.palette.primaryButton.main,
      opacity: 0.7,
    },
    width: "90%",
    // fontWeight: 'bold',
  },
}))(Button);

const useStyles = makeStyles((theme) => ({
  customisationContainer: {
    padding: 24,
    borderRadius: 16,
    color: theme.palette.primaryFont.main,
    fontSize: 16,
    // fontWeight: 'bold',
    width: "75%",
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 16,
    color: theme.palette.secondaryFont.main,
  },
  subTitle: {
    fontSize: 20,
    fontWeight: "bold",
    marginBottom: 16,
    marginTop: 16,
    color: theme.palette.secondaryFont.main,
  },
  customUnit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 8,
    marginTop: 8,
  },
  customInputContainer: {
    width: "60%",
    display: "flex",
    alignItems: "center",
  },
  inputLabel: { width: 215 },
  picker: { marginLeft: 16, marginRight: 16 },
  pickerInput: { width: 175 },
  customDisplay: {},
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function Customisation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  let jwtToken = localStorage.getItem("clientToken");

  const { content, showAlert } = useSelector(selectContents);
  const { customisation } = useSelector(selectCustomisation);
  const { userAccount } = useSelector(selectUsers);

  const handleUpload = (jsonData, filename) => {
    const fileData = JSON.stringify(jsonData);
    const blob = new Blob([fileData], { type: "text/plain" });

    let data = {
      objectName: filename,
      objectType: "asset",
      token: jwtToken,
      file: blob,
      contentType: blob.type,
      assetURL: customisation.theme.cloudfrontAssetURL,
    };

    dispatch(preSignedURLcontents(data));
  };

  const [primaryColor, setPrimaryColor] = useState(
    customisation.theme.primaryColor
  );
  const [secondaryColor, setSecondaryColor] = useState(
    customisation.theme.secondaryColor
  );
  const [alternativeColor, setAlternativeColor] = useState(
    customisation.theme.alternativeColor
  );

  const [primaryFontColor, setPrimaryFontColor] = useState(
    customisation.theme.primaryFontColor
  );
  const [secondaryFontColor, setSecondaryFontColor] = useState(
    customisation.theme.secondaryFontColor
  );
  const [alternativeFontColor, setAlternativeFontColor] = useState(
    customisation.theme.alternativeFontColor
  );

  const [highlightColor, setHighlightColor] = useState(
    customisation.theme.highlightColor
  );
  const [fontFamily, setFontFamily] = useState(customisation.theme.fontFamily);

  const [primaryButtonColor, setPrimaryButtonColor] = useState(
    customisation.theme.primaryButtonColor
  );
  const [primaryButtonFontColor, setPrimaryButtonFontColor] = useState(
    customisation.theme.primaryButtonFontColor
  );

  const [secondaryButtonColor, setSecondaryButtonColor] = useState(
    customisation.theme.secondaryButtonColor
  );
  const [secondaryButtonFontColor, setSecondaryButtonFontColor] = useState(
    customisation.theme.secondaryButtonFontColor
  );

  const [headerButtonColor, setHeaderButtonColor] = useState(
    customisation.theme.headerButtonColor
  );
  const [headerButtonFontColor, setHeaderButtonFontColor] = useState(
    customisation.theme.headerButtonFontColor
  );

  const [headerColor, setHeaderColor] = useState(
    customisation.theme.headerColor
  );
  const [headerFontColor, setHeaderFontColor] = useState(
    customisation.theme.headerFontColor
  );
  const [footerColor, setFooterColor] = useState(
    customisation.theme.footerColor
  );
  const [footerFontColor, setFooterFontColor] = useState(
    customisation.theme.footerFontColor
  );
  const [appbarColor, setAppbarColor] = useState(
    customisation.theme.appbarColor
  );
  const [appbarFontColor, setAppbarFontColor] = useState(
    customisation.theme.appbarFontColor
  );
  const [menuColor, setMenuColor] = useState(customisation.theme.menuColor);
  const [menuFontColor, setMenuFontColor] = useState(
    customisation.theme.menuFontColor
  );
  const [subMenuColor, setSubMenuColor] = useState(
    customisation.theme.subMenuColor
  );
  const [subMenuFontColor, setSubMenuFontColor] = useState(
    customisation.theme.subMenuFontColor
  );
  const [landingTileFontColor, setLandingTileFontColor] = useState(
    customisation.theme.landingTileFontColor
  );
  const [landingTileButtonFontColor, setLandingTileButtonFontColor] = useState(
    customisation.theme.landingTileButtonFontColor
  );
  const [pointsColor, setPointsColor] = useState(
    customisation.theme.pointsColor
  );
  const [showAlertState, setShowAlertState] = useState(
    showAlert.value === "true"
  );

  useEffect(() => {
    setPrimaryColor(customisation.theme.primaryColor);
    setSecondaryColor(customisation.theme.secondaryColor);
    setAlternativeColor(customisation.theme.alternativeColor);

    setPrimaryFontColor(customisation.theme.primaryFontColor);
    setSecondaryFontColor(customisation.theme.secondaryFontColor);
    setAlternativeFontColor(customisation.theme.alternativeFontColor);
    setHighlightColor(customisation.theme.highlightColor);

    setFontFamily(customisation.theme.fontFamily);

    setPrimaryButtonColor(customisation.theme.primaryButtonColor);
    setPrimaryButtonFontColor(customisation.theme.primaryButtonFontColor);

    setSecondaryButtonColor(customisation.theme.secondaryButtonColor);
    setSecondaryButtonFontColor(customisation.theme.secondaryButtonFontColor);

    setHeaderButtonColor(customisation.theme.headerButtonColor);
    setHeaderButtonFontColor(customisation.theme.headerButtonFontColor);

    setHeaderColor(customisation.theme.headerColor);
    setHeaderFontColor(customisation.theme.headerFontColor);
    setFooterColor(customisation.theme.footerColor);
    setFooterFontColor(customisation.theme.footerFontColor);
    setAppbarColor(customisation.theme.appbarColor);
    setAppbarFontColor(customisation.theme.appbarFontColor);
    setMenuColor(customisation.theme.menuColor);
    setMenuFontColor(customisation.theme.menuFontColor);
    setSubMenuColor(customisation.theme.subMenuColor);
    setSubMenuFontColor(customisation.theme.subMenuFontColor);
    setLandingTileFontColor(customisation.theme.landingTileFontColor);
    setLandingTileButtonFontColor(customisation.theme.landingTileButtonFontColor);
    setPointsColor(customisation.theme.pointsColor);
  }, [customisation]);

  const handleSave = () => {
    let themeData = {
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      alternativeColor: alternativeColor,
      primaryButtonColor: primaryButtonColor,
      secondaryButtonColor: secondaryButtonColor,
      headerButtonColor: headerButtonColor,
      primaryButtonFontColor: primaryButtonFontColor,
      secondaryButtonFontColor: secondaryButtonFontColor,
      headerButtonFontColor: headerButtonFontColor,
      primaryFontColor: primaryFontColor,
      secondaryFontColor: secondaryFontColor,
      alternativeFontColor: alternativeFontColor,
      highlightColor: highlightColor,
      headerColor: headerColor,
      headerFontColor: headerFontColor,
      footerColor: footerColor,
      footerFontColor: footerFontColor,
      appbarColor: appbarColor,
      appbarFontColor: appbarFontColor,
      menuColor: menuColor,
      menuFontColor: menuFontColor,
      subMenuColor: subMenuColor,
      subMenuFontColor: subMenuFontColor,
      landingTileFontColor: landingTileFontColor,
      landingTileButtonFontColor: landingTileButtonFontColor,
      pointsColor: pointsColor,
      fontFamily: fontFamily,
    };
    dispatch(setCustomisation(themeData));
  };

  const handleClick = () => {
    let themeData = {
      primaryColor: primaryColor,
      secondaryColor: secondaryColor,
      alternativeColor: alternativeColor,
      primaryButtonColor: primaryButtonColor,
      secondaryButtonColor: secondaryButtonColor,
      headerButtonColor: headerButtonColor,
      primaryButtonFontColor: primaryButtonFontColor,
      secondaryButtonFontColor: secondaryButtonFontColor,
      headerButtonFontColor: headerButtonFontColor,
      primaryFontColor: primaryFontColor,
      secondaryFontColor: secondaryFontColor,
      alternativeFontColor: alternativeFontColor,
      highlightColor: highlightColor,
      headerColor: headerColor,
      headerFontColor: headerFontColor,
      footerColor: footerColor,
      footerFontColor: footerFontColor,
      appbarColor: appbarColor,
      appbarFontColor: appbarFontColor,
      menuColor: menuColor,
      menuFontColor: menuFontColor,
      subMenuColor: subMenuColor,
      subMenuFontColor: subMenuFontColor,
      landingTileFontColor: landingTileFontColor,
      landingTileButtonFontColor: landingTileButtonFontColor,
      pointsColor: pointsColor,
      fontFamily: fontFamily,
    };
    dispatch(setCustomisation(themeData));

    handleUpload(themeData, "theme.json");
  };

  const fontList = [
    { name: "Open Sans Regular", value: "Open Sans,sans-serif" },
    { name: "Arial", value: "Arial,sans-serif" },
    { name: "Arial Black", value: "Arial Black,sans-serif" },
    { name: "Verdana", value: "Verdana,sans-serif" },
    { name: "Tahoma", value: "Tahoma,sans-serif" },
    { name: "Trebuchet MS", value: "Trebuchet MS,sans-serif" },
    { name: "Impact", value: "Impact,sans-serif" },
    { name: "Times New Roman", value: "Times New Roman,serif" },
    { name: "Didot", value: "Didot,serif" },
    { name: "Georgia", value: "Georgia,serif" },
    { name: "American Typewriter", value: "American Typewriter,serif" },
    { name: "Andalé Mono", value: "Andalé Mono,monospace" },
    { name: "Courier", value: "Courier,monospace" },
    { name: "Lucida Console", value: "Lucida Console,monospace" },
    { name: "Monaco", value: "Monaco,monospace" },
    { name: "Bradley Hand", value: "Bradley Hand,cursive" },
    { name: "Brush Script MT", value: "Brush Script MT,cursive" },
    { name: "Luminari", value: "Luminari,fantasy" },
    { name: "Comic Sans MS", value: "Comic Sans MS,cursive" },
  ];

  return (
    <Paper elevation={3} className={classes.customisationContainer}>
      <div className={classes.title}>Site Customisation</div>
      <div className={classes.subTitle}>Colours</div>
      {/*  colors */}
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="primaryColor">
            Primary Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="primaryColor"
            name="primaryColor"
            value={primaryColor}
            onChange={(e) => setPrimaryColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="primaryColorText"
            name="primaryColorText"
            value={primaryColor}
            onChange={(e) => setPrimaryColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="secondaryColor">
            Secondary Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="secondaryColor"
            name="secondaryColor"
            value={secondaryColor}
            onChange={(e) => setSecondaryColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="setSecondaryColor"
            name="setSecondaryColor"
            value={secondaryColor}
            onChange={(e) => setSecondaryColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="alternativeColor">
            Alternative Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="alternativeColor"
            name="alternativeColor"
            value={alternativeColor}
            onChange={(e) => setAlternativeColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="alternativeColorText"
            name="alternativeColorText"
            value={alternativeColor}
            onChange={(e) => setAlternativeColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="highlightColor">
            Highlight Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="highlightColor"
            name="highlightColor"
            value={highlightColor}
            onChange={(e) => setHighlightColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="highlightColorText"
            name="highlightColorText"
            value={highlightColor}
            onChange={(e) => setHighlightColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="headerColor">
            Header Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="headerColor"
            name="headerColor"
            value={headerColor}
            onChange={(e) => setHeaderColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="headerColorText"
            name="headerColorText"
            value={headerColor}
            onChange={(e) => setHeaderColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="appbarColor">
            App Bar Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="appbarColor"
            name="appbarColor"
            value={appbarColor}
            onChange={(e) => setAppbarColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="appbarColorText"
            name="appbarColorText"
            value={appbarColor}
            onChange={(e) => setAppbarColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="menuColor">
            Menu Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="menuColor"
            name="menuColor"
            value={menuColor}
            onChange={(e) => setMenuColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="menuColorText"
            name="menuColorText"
            value={menuColor}
            onChange={(e) => setMenuColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="subMenuColor">
            Sub Menu Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="subMenuColor"
            name="subMenuColor"
            value={subMenuColor}
            onChange={(e) => setSubMenuColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="subMenuColorText"
            name="subMenuColorText"
            value={subMenuColor}
            onChange={(e) => setSubMenuColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="footerColor">
            Footer Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="footerColor"
            name="footerColor"
            value={footerColor}
            onChange={(e) => setFooterColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="footerColorText"
            name="footerColorText"
            value={footerColor}
            onChange={(e) => setFooterColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="pointsColor">
            Product Card Points Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="pointsColor"
            name="pointsColor"
            value={pointsColor}
            onChange={(e) => setPointsColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="pointsColorText"
            name="pointsColorText"
            value={pointsColor}
            onChange={(e) => setPointsColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <Divider style={{ marginTop: 16 }} />
      {/* fonts */}

      <div className={classes.subTitle}>Fonts</div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="fontFamily">
            Font Family
          </label>
          <input
            className={classes.picker}
            type="color"
            style={{ visibility: "hidden" }}
          />
          <select
            className={classes.pickerInput}
            style={{ width: 183 }}
            id="fontFamily"
            name="fontFamily"
            onChange={(e) => setFontFamily(e.target.value.split(","))}
            defaultValue={fontFamily.length ? fontFamily.join(",") : fontFamily}
          >
            {fontList.map((font) => (
              <option style={{ fontFamily: font.value }} value={font.value}>
                {font.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="primaryFontColor">
            Primary Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="primaryFontColor"
            name="primaryFontColor"
            value={primaryFontColor}
            onChange={(e) => setPrimaryFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="primaryFontColorText"
            name="primaryFontColorText"
            value={primaryFontColor}
            onChange={(e) => setPrimaryFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="secondaryFontColor">
            Secondary Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="secondaryFontColor"
            name="secondaryFontColor"
            value={secondaryFontColor}
            onChange={(e) => setSecondaryFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="secondaryFontColorText"
            name="secondaryFontColorText"
            value={secondaryFontColor}
            onChange={(e) => setSecondaryFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="alternativeFontColor">
            Alternative Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="alternativeFontColor"
            name="alternativeFontColor"
            value={alternativeFontColor}
            onChange={(e) => setAlternativeFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="alternativeFontColorText"
            name="alternativeFontColorText"
            value={alternativeFontColor}
            onChange={(e) => setAlternativeFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      {/* <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="headerFontColor">
            Header Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="headerFontColor"
            name="headerFontColor"
            value={headerFontColor}
            onChange={(e) => setHeaderFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="headerFontColorText"
            name="headerFontColorText"
            value={headerFontColor}
            onChange={(e) => setHeaderFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div> */}

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="appbarFontColor">
            App Bar Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="appbarFontColor"
            name="appbarFontColor"
            value={appbarFontColor}
            onChange={(e) => setAppbarFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="appbarFontColorText"
            name="appbarFontColorText"
            value={appbarFontColor}
            onChange={(e) => setAppbarFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="menuFontColor">
            Menu Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="menuFontColor"
            name="menuFontColor"
            value={menuFontColor}
            onChange={(e) => setMenuFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="menuFontColorText"
            name="menuFontColorText"
            value={menuFontColor}
            onChange={(e) => setMenuFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="subMenuFontColor">
            Sub Menu Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="subMenuFontColor"
            name="subMenuFontColor"
            value={subMenuFontColor}
            onChange={(e) => setSubMenuFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="subMenuFontColorText"
            name="subMenuFontColorText"
            value={subMenuFontColor}
            onChange={(e) => setSubMenuFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="landingTileFontColor">
            Landing Tile Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="landingTileFontColor"
            name="landingTileFontColor"
            value={landingTileFontColor}
            onChange={(e) => setLandingTileFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="landingTileFontColorText"
            name="landingTileFontColorText"
            value={landingTileFontColor}
            onChange={(e) => setLandingTileFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="landingTileButtonFontColor">
            Landing Tile Button Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="landingTileButtonFontColor"
            name="landingTileButtonFontColor"
            value={landingTileButtonFontColor}
            onChange={(e) => setLandingTileButtonFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="landingTileButtonFontColorText"
            name="landingTileButtonFontColorText"
            value={landingTileButtonFontColor}
            onChange={(e) => setLandingTileButtonFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="footerFontColor">
            Footer Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="footerFontColor"
            name="footerFontColor"
            value={footerFontColor}
            onChange={(e) => setFooterFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="footerFontColorText"
            name="footerFontColorText"
            value={footerFontColor}
            onChange={(e) => setFooterFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <Divider style={{ marginTop: 16 }} />
      {/* buttons */}
      <div className={classes.subTitle}>Primary Button</div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="primaryButtonColor">
            Primary Button Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="primaryButtonColor"
            name="primaryButtonColor"
            value={primaryButtonColor}
            onChange={(e) => setPrimaryButtonColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="primaryButtonColorText"
            name="primaryButtonColorText"
            value={primaryButtonColor}
            onChange={(e) => setPrimaryButtonColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="primaryButtonFontColor">
            Primary Button Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="primaryButtonFontColor"
            name="primaryButtonFontColor"
            value={primaryButtonFontColor}
            onChange={(e) => setPrimaryButtonFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="primaryButtonFontColorText"
            name="primaryButtonFontColorText"
            value={primaryButtonFontColor}
            onChange={(e) => setPrimaryButtonFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <Divider style={{ marginTop: 16 }} />

      <div className={classes.subTitle}>Secondary Button</div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="secondaryButtonColor">
            Secondary Button Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="secondaryButtonColor"
            name="secondaryButtonColor"
            value={secondaryButtonColor}
            onChange={(e) => setSecondaryButtonColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="secondaryButtonColorText"
            name="secondaryButtonColorText"
            value={secondaryButtonColor}
            onChange={(e) => setSecondaryButtonColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="secondaryButtonFontColor">
            Secondary Button Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="secondaryButtonFontColor"
            name="secondaryButtonFontColor"
            value={secondaryButtonFontColor}
            onChange={(e) => setSecondaryButtonFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="secondaryButtonFontColorText"
            name="secondaryButtonFontColorText"
            value={secondaryButtonFontColor}
            onChange={(e) => setSecondaryButtonFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>

      <Divider style={{ marginTop: 16, marginBottom: 16 }} />
      <div className={classes.subTitle}>Header Button</div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="headerButtonColor">
            Header Button Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="headerButtonColor"
            name="headerButtonColor"
            value={headerButtonColor}
            onChange={(e) => setHeaderButtonColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="headerButtonColorText"
            name="headerButtonColorText"
            value={headerButtonColor}
            onChange={(e) => setHeaderButtonColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <div className={classes.customUnit}>
        <div className={classes.customInputContainer}>
          <label className={classes.inputLabel} for="headerButtonFontColor">
            Header Button Font Colour:
          </label>
          <input
            className={classes.picker}
            type="color"
            id="headerButtonFontColor"
            name="headerButtonFontColor"
            value={headerButtonFontColor}
            onChange={(e) => setHeaderButtonFontColor(e.target.value)}
          />
          <input
            className={classes.pickerInput}
            type="text"
            id="headerButtonFontColorText"
            name="headerButtonFontColorText"
            value={headerButtonFontColor}
            onChange={(e) => setHeaderButtonFontColor(e.target.value)}
          />
        </div>
        <div className={classes.customDisplay}></div>
      </div>
      <Divider style={{ marginTop: 16, marginBottom: 16 }} />

      <div className={classes.buttonsContainer}>
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <ColorButton onClick={handleSave}>Update Locally</ColorButton>
        </div>
        <div
          style={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}
        >
          <ColorButton
            disabled={userAccount.roleID !== 1}
            onClick={handleClick}
          >
            Save Changes
          </ColorButton>
        </div>
      </div>
      <div
        style={{
          textAlign: "left",
          fontStyle: "italic",
          fontSize: 18,
          marginBottom: 16,
          marginTop: 16,
        }}
      >
        Please note, only Super Admins are able to save changes permantently.
        Changes may take a few minutes to populate, please reload the page if
        they aren't showing.
      </div>
    </Paper>
  );
}
