import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Typography from "@material-ui/core/Typography";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ClearIcon from "@material-ui/icons/Clear";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { replaceUrlParam } from "../app/utils";
import BreadCrumbs from "../common/BreadCrumbs";
import { selectCategories } from "../features/categories/categoriesSlice";
import {
  fetchMoreProducts,
  fetchProducts,
  fetchProductsMaxPoints,
  selectCoreProducts,
} from "../features/products/coreProductsSlice";
import { selectUsers } from "../features/users/usersSlice";
import MobileMenu from "./MobileMenu";
import MobileProductCard from "./MobileProductCard";
import ProductCard from "./ProductCard";

var sortBy = require("lodash/sortBy");

const useStyles = makeStyles((theme) => ({
  categoryContainer: {},
  bannerContainer: {},
  cardsContainer: {
    padding: theme.spacing(3),
    // alignItems: 'center',
    // color:  theme.palette.primary.main,
    // display: 'flex',
    // flexDirection: 'row',
    // height: '75px',
    // justifyContent: 'center',
  },
  sortContainer: {
    marginBottom: 16,
    display: "flex",
    flexDirection: "column",
    width: 275,
  },
  priceContainer: {
    minWidth: 250,
  },
  sortItemsContainer: {
    display: "flex",
  },

  sortDropdown: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: theme.palette.secondaryButton.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 2,
    paddingRight: 24,
    textTransform: "capitalize",
  },

  brandSelect: {
    backgroundColor: theme.palette.secondaryButton.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryFont.main,
    // fontSize: 18,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 2,
    // paddingRight: 24,
    textTransform: "capitalize",
  },
  textInput: {
    backgroundColor: theme.palette.secondaryButton.main,
    borderWidth: 3,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryFont.main,
    fontSize: 18,
    fontWeight: "bold",
    padding: 0,
    paddingLeft: 24,
    paddingRight: 24,
    textTransform: "capitalize",
    width: 250,
    height: 34.5,
  },
  filterContainer: {
    display: "flex",
    // justifyContent: 'space-between',
    alignItems: "center",
    marginBottom: 24,
  },
  filterItem: {
    marginRight: 48,
    display: "flex",
  },
  more: {
    backgroundColor: theme.palette.secondaryButton.main,
    borderStyle: "solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: "24px",
    color: theme.palette.secondaryFont.main,
    fontWeight: "bold",
    padding: 4,
    paddingLeft: 24,
    paddingRight: 24,
    textTransform: "capitalize",
    width: 120,
    cursor: "pointer",
    textAlign: "center",
  },

  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  categoryTitleColor: {
    color: theme.palette.secondaryFont.main,
  },
  breadcrumbs: {
    padding: 24,
    paddingBottom: 8,
    paddingTop: 8,
    borderBottom: "2px solid #F4F4F4",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  soon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 50,
    fontS: 32,
    color: theme.palette.primaryFont.main,
    fontWeight: "bold",
  },
  catTitle: {
    color: theme.palette.primaryFont.main,
    fontSize: 26,
    textAlign: "left",
    fontWeight: "bold",
    padding: 24,
    paddingBottom: 10,
    textTransform: "capitalize",
  },
  quantityBox: {
    display: "flex",
    flexFlow: "row nowrap",
    border: "3px solid",
    borderColor: theme.palette.secondaryFont.main,
    borderRadius: 24,
    height: 25,
    width: 92,
    padding: 4,
  },
  quantityAmount: {
    color: theme.palette.primary.main,
    height: 25,
    width: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
    textAlign: "center",
    marginLeft: 5,
    fontSize: 16,
  },
  quantityControls: {
    borderLeft: "2px solid #DDD",
    height: 25,
    width: 25,
  },
  quantityIncrease: {
    borderBottom: "2px solid #DDD",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 12.5,
    width: 25,
  },
  quantityDecrease: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 12.5,
    width: 25,
  },
}));

function valuetext(value) {
  return `${value}`;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

function compare(a, b) {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
}

export default function CategoryContainer(props) {
  const matches = useMediaQuery("(min-width:850px)");
  const midpoint = useMediaQuery("(max-width:1450px)");

  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const [sortCategory, setSortCategory] = useState("created_at");

  console.log();
  console.log(sortCategory);

  useEffect(() => {
    if (props.location.pathname.includes("search")) {
      setSortCategory("rank");
    } else if (
      window.location.pathname
        .split("/")
        .filter((x) => x)
        .filter((x) => x !== "products")[0] == "taylors-wines-exclusives"
    ) {
      setSortCategory("points");
    } else {
      setSortCategory("created_at");
    }
  }, [window.location.pathname]);

  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    firstUpdate.current = false;
    setSortCategory(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [selected, setSelected] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numberShown, setNumberShown] = useState(24);
  const [imgSrc, setImgSrc] = useState("");
  const [brandName, setBrand] = useState([]);
  const { pathname } = useLocation();

  const { users, user, userAccount, signedIn } = useSelector(selectUsers);
  const { categoriesTree } = useSelector(selectCategories);
  const [uA, setUA] = useState("");

  const [moreProducts, setMoreProducts] = useState(true);

  const [buttonsDisabled, setButtonsDisabled] = useState(false);

  const firstUpdate = useRef(true);

  useEffect(() => {
    if (users.loading === "loaded") {
      setUA(userAccount);
    }
  }, [users.loading]);

  //categories
  const {
    queryParam,
    brandsList,
    productList,
    loading,
    lastQuery,
    searchCategories,
    productsAmount,
    maxPoints,
  } = useSelector(selectCoreProducts);
  const [priceRange, setPriceRange] = useState([
    0,
    Math.ceil(maxPoints / 100) * 100,
  ]);

  useEffect(() => {
    if (!maxPoints) {
      dispatch(
        fetchProductsMaxPoints({
          token: jwtToken,
        })
      );
    } else {
      if (priceRange[1] === 0) {
        setPriceRange([0, Math.ceil(maxPoints / 100) * 100]);
      }
    }
  }, [maxPoints]);

  let jwtToken = localStorage.getItem("clientToken");
  const [brandIDs, setBrandIDs] = useState([]);

  const iconComponent = (props) => {
    return <ExpandMoreIcon className={props.className + " " + classes.icon} />;
  };

  useEffect(() => {
    firstUpdate.current = true;
  }, [props.location.pathname]);

  useEffect(() => {
    let src = props.location.pathname.split("/")[1];
    if (src && src !== "products") {
      if (process.env.REACT_APP_CLIENT_ID == "12" && src == "experiences") {
        setImgSrc("experiences-taylors.png");
      } else {
        src = `/${src.toLowerCase()}.png`;
        setImgSrc(src);
      }
    } else {
      setImgSrc(false);
    }
    setBrand([]);
  }, [props.location]);

  useEffect(() => {
    if (productList.data.length < productsAmount) {
      setMoreProducts(true);
    } else {
      setMoreProducts(false);
    }
  }, [productList.data.length, productsAmount]);

  useEffect(() => {
    if (
      productList.data.length === 0 &&
      loading === "idle" &&
      categoriesTree.length &&
      !props.location.pathname.includes("search")
    ) {
      handleRefresh();
    }
  }, [productList, loading, categoriesTree]);

  const handleRefresh = () => {
    const pathArr = window.location.pathname
      .split("/")
      .filter((x) => x)
      .filter((x) => x !== "products");
    let categoryID = "";
    if (pathArr.length === 1) {
      const filtered = categoriesTree.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[0].split("-").join(" ").toUpperCase()
      );
      categoryID = filtered[0].id;
    } else if (pathArr.length === 2) {
      const top = categoriesTree.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[0].split("-").join(" ").toUpperCase()
      );
      const second = top[0].subCategories.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[1].split("-").join(" ").toUpperCase()
      );
      categoryID = second[0].id;
    } else if (pathArr.length === 3) {
      const top = categoriesTree.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[0].split("-").join(" ").toUpperCase()
      );
      const second = top[0].subCategories.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[1].split("-").join(" ").toUpperCase()
      );
      const third = second[0].subCategories.filter(
        (cat) =>
          cat.name.split("-").join(" ").toUpperCase() ===
          pathArr[2].split("-").join(" ").toUpperCase()
      );
      categoryID = third[0].id;
    }
    setCurrentPage(1);

    let payload = {
      token: jwtToken,
      query: `?categories=${categoryID}&limit=24`,
    };

    if (pathArr[0] == "taylors-wines-exclusives") {
      payload[
        "query"
      ] = `?categories=${categoryID}&limit=24&order_by=points&sort=asc`;
    }

    dispatch(fetchProducts(payload));
  };

  const handleRangeMinChange = (event) => {
    let newRange = [...priceRange];
    newRange[0] = event.target.value;
    setPriceRange(newRange);
  };

  const incMinRange = (event) => {
    let newRange = [...priceRange];
    newRange[0] = Number(newRange[0]) + 1;
    setPriceRange(newRange);
  };

  const decMinRange = (event) => {
    let newRange = [...priceRange];
    if (newRange[0] > 0) {
      newRange[0] = newRange[0] - 1;
    }
    setPriceRange(newRange);
  };

  const handleRangeMaxChange = (event) => {
    let newRange = [...priceRange];
    newRange[1] = event.target.value;
    setPriceRange(newRange);
  };

  const incMaxRange = (event) => {
    let newRange = [...priceRange];
    newRange[1] = Number(newRange[1]) + 1;
    setPriceRange(newRange);
  };

  const decMaxRange = (event) => {
    let newRange = [...priceRange];
    if (newRange[1] > 1) {
      newRange[1] = newRange[1] - 1;
    }
    setPriceRange(newRange);
  };

  const handleMore = () => {
    if (productList.data.length < productsAmount) {
      dispatch(
        fetchMoreProducts({
          token: jwtToken,
          query: lastQuery,
          offset: numberShown,
        })
      );
    } else if (productList.data.length == productsAmount) {
      setMoreProducts(false);
    }
    setCurrentPage(currentPage + 1);
    setNumberShown(numberShown + 24);
  };

  const handleSearch = () => {
    let searchQuery = lastQuery ? lastQuery : "";

    if (brandIDs.length) {
      searchQuery = replaceUrlParam(searchQuery, "brands", brandIDs.join(","));
    }

    searchQuery = replaceUrlParam(searchQuery, "points", priceRange.join(","));

    if (!props.location.pathname.includes("search")) {
      searchQuery = replaceUrlParam(
        searchQuery,
        "categories",
        searchCategories
      );
    }

    if (props.location.pathname.includes("search")) {
      searchQuery = replaceUrlParam(searchQuery, "query", queryParam);
    }

    if (sortCategory != null && sortCategory != "rank") {
      if (sortCategory === "points-reverse") {
        searchQuery = replaceUrlParam(searchQuery, "order_by", "points");
        searchQuery = replaceUrlParam(searchQuery, "sort", "desc");
      } else if (sortCategory === "created_at") {
        searchQuery = replaceUrlParam(searchQuery, "order_by", sortCategory);
        searchQuery = replaceUrlParam(searchQuery, "sort", "desc");
      } else if (sortCategory === "points") {
        searchQuery = replaceUrlParam(searchQuery, "order_by", sortCategory);
        searchQuery = replaceUrlParam(searchQuery, "sort", "asc");
      } else {
        searchQuery = replaceUrlParam(searchQuery, "order_by", sortCategory);
      }
    }

    dispatch(
      fetchProducts({
        query: searchQuery,
        token: jwtToken,
      })
    );
    // setBrandIDs()
    setCurrentPage(1);
  };

  const handleClear = () => {
    setPriceRange([0, Math.ceil(maxPoints / 100) * 100]);
    setSortCategory(
      props.location.pathname.includes("search") ? "rank" : "created_at"
    );
    setBrandIDs([]);

    // dispatch(
    //   fetchProducts({
    //     token: jwtToken,
    //     query: `?limit=24`,
    //   })
    // );
    // setCurrentPage(1);
    handleRefresh();
  };

  const handleBrandsChange = (event) => {
    let idsArr = [...brandIDs];

    event.target.value.forEach((selected) => {
      let selectedBrand = brandsList.filter((brand) => brand.id === selected);
      if (selectedBrand[0]) {
        if (idsArr.some((id) => id === selectedBrand[0].id)) {
          idsArr = idsArr.filter((id) => id !== selectedBrand[0].id);
        } else {
          idsArr.push(selectedBrand[0].id);
        }
      }
    });

    setBrandIDs(idsArr);
  };

  const handleDrawerClose = () => {
    setState({ ...state, left: false });
  };
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction !== "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      {!matches && <MobileMenu onClick={toggleDrawer(anchor, false)} />}
    </div>
  );

  const filterList = (anchor) => (
    <div className={classes.list} role="presentation">
      <div className={classes.drawerHeader}>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction !== "rtl" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>
      <Divider />
      {!matches && <MobileMenu onClick={setFiltersOpen(false)} />}
    </div>
  );

  const [filtersOpen, setFiltersOpen] = useState(false);

  const handleFilteersOpen = () => {
    setFiltersOpen(true);
  };

  const handleFiltersClose = () => {
    setFiltersOpen(false);
  };
  const pathnames = pathname
    .split("/")
    .filter((x) => x)
    .filter((x) => x !== "products");
  const subCatSelected = props.location.pathname.split("/").length > 2;

  return (
    <div className={classes.categoryContainer}>
      {!props.location.pathname.includes("search") &&
        !subCatSelected &&
        imgSrc && (
          <div
            className={classes.bannerContainer}
            style={{
              backgroundImage: `url(${imgSrc})`,
              backgroundColor: "#cccccc",
              height: matches ? 450 : 138,
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              position: "relative",
            }}
          >
            {/* <img
            style={{
              width: '100%',
              margin: 0,
            }}
            src={imgSrc}
          /> */}
          </div>
        )}
      {!window.location.pathname.includes("coming-soon") && (
        <div className={classes.breadcrumbs}>
          <BreadCrumbs />
        </div>
      )}
      <div>
        <Typography className={classes.catTitle}>
          {pathnames.length > 1 &&
            pathnames[pathnames.length - 1].split("-").join(" ")}
        </Typography>
      </div>
      {window.location.pathname.includes("coming-soon") ? (
        <div className={classes.soon}>Coming Soon</div>
      ) : (
        <div className={classes.cardsContainer}>
          <div
            style={{
              display: matches ? "flex" : "none",
              borderBottom: "2px solid #F4F4F4;",
              marginBottom: 24,
            }}
          >
            <div>
              <div
                className={classes.categoryTitleColor}
                style={{
                  fontSize: midpoint ? 16 : 18,
                  fontWeight: "bold",
                  marginBottom: 16,
                }}
              >
                Filter By
              </div>
              <div className={classes.filterContainer}>
                <div className={classes.filterItem}>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="brand-mutiple-checkbox-label"
                      id="brand-mutiple-checkbox"
                      multiple
                      value={brandName}
                      onChange={handleBrandsChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={
                        <Input
                          style={{
                            fontSize: midpoint ? 16 : 18,
                            width: midpoint ? 225 : 250,
                          }}
                          className={classes.brandSelect}
                        />
                      }
                      renderValue={(selected) => "Brand"}
                      MenuProps={MenuProps}
                      displayEmpty
                    >
                      {brandsList.map((brand) => (
                        <MenuItem
                          key={brand.name}
                          name={brand.id}
                          value={brand.id}
                          style={{ color: theme.palette.primary.main }}
                        >
                          <Checkbox checked={brandIDs.indexOf(brand.id) > -1} />
                          <ListItemText
                            primary={`${brand.name} (${brand.count}) `}
                          />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>

            <div>
              <div className={classes.priceContainer}>
                <div
                  className={classes.categoryTitleColor}
                  style={{
                    fontSize: midpoint ? 16 : 18,

                    fontWeight: "bold",
                    marginBottom: 16,
                  }}
                >
                  Price Range
                </div>
                <div
                  style={{ justifyContent: "space-around", marginRight: 25 }}
                  className={classes.filterItem}
                >
                  <div className={classes.quantityBox}>
                    <input
                      type="text"
                      onChange={(e) => {
                        handleRangeMinChange(e);
                      }}
                      value={priceRange && priceRange[0]}
                      className={classes.quantityAmount}
                    />
                    <div className={classes.quantityControls}>
                      <div className={classes.quantityIncrease}>
                        <KeyboardArrowUpIcon
                          style={{ fontSize: 14 }}
                          onClick={(e) => incMinRange(e)}
                        />
                      </div>
                      <div className={classes.quantityDecrease}>
                        <KeyboardArrowDownIcon
                          style={{ fontSize: 14 }}
                          onClick={(e) => decMinRange(e)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={classes.quantityBox}>
                    <input
                      type="text"
                      onChange={(e) => {
                        handleRangeMaxChange(e);
                      }}
                      value={priceRange && priceRange[1]}
                      className={classes.quantityAmount}
                    />
                    <div className={classes.quantityControls}>
                      <div className={classes.quantityIncrease}>
                        <KeyboardArrowUpIcon
                          style={{ fontSize: 14 }}
                          onClick={(e) => incMaxRange(e)}
                        />
                      </div>
                      <div className={classes.quantityDecrease}>
                        <KeyboardArrowDownIcon
                          style={{ fontSize: 14 }}
                          onClick={(e) => decMaxRange(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.sortContainer}>
                <div
                  className={classes.categoryTitleColor}
                  style={{
                    fontSize: midpoint ? 16 : 18,

                    fontWeight: "bold",
                    marginBottom: 16,
                  }}
                >
                  Sort By
                </div>

                <div>
                  <FormControl className={classes.formControl}>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      open={open}
                      onClose={handleClose}
                      onOpen={handleOpen}
                      value={sortCategory}
                      onChange={handleChange}
                      IconComponent={iconComponent}
                      classes={{ root: classes.select }}
                      input={
                        <Input
                          style={{
                            fontSize: midpoint ? 16 : 18,
                            width: midpoint ? 225 : 250,
                          }}
                          className={classes.brandSelect}
                        />
                      }
                      MenuProps={MenuProps}
                    >
                      {props.location.pathname.includes("search") && (
                        <MenuItem
                          style={{ color: theme.palette.primary.main }}
                          value={"rank"}
                        >
                          Relevance
                        </MenuItem>
                      )}
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={"created_at"}
                      >
                        Recently Added
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={"points"}
                      >
                        Lowest Points First
                      </MenuItem>
                      <MenuItem
                        style={{ color: theme.palette.primary.main }}
                        value={"points-reverse"}
                      >
                        Highest Points First
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <div>
              <div style={{ paddingRight: 10 }}>
                <div
                  style={{
                    marginTop: 36,
                  }}
                >
                  <div
                    onClick={handleSearch}
                    style={{
                      fontSize: 22,
                      padding: 4,
                      paddingLeft: 8,
                      paddingRight: 8,
                      backgroundColor: theme.palette.secondaryFont.main,
                      cursor: "pointer",
                      borderWidth: 3,
                      borderStyle: "solid",
                      borderColor: theme.palette.secondaryFont.main,
                      borderRadius: "24px",
                      color: "white",
                    }}
                  >
                    GO
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div>
                <div
                  style={{
                    marginTop: 34,
                  }}
                >
                  <ClearIcon
                    style={{
                      fontSize: 44,
                      display: "flex",
                      height: 44,
                      color: theme.palette.secondaryFont.main,
                      cursor: "pointer",
                    }}
                    onClick={handleClear}
                  />
                </div>
              </div>
            </div>
          </div>

          {productList.data.length ? (
            <InfiniteScroll
              dataLength={productList.data.length}
              next={handleMore}
              hasMore={moreProducts}
              loader={
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 20,
                  }}
                >
                  Loading...
                </div>
              }
              style={{
                overflowY: "hidden",
                overflowX: "hidden",
                paddingBottom: 10,
              }}
            >
              <Grid
                container
                spacing={3}
                // direction="row wrap"
                justifyContent="flex-start"
                style={{ paddingLeft: matches && 10 }}
              >
                {productList.data
                  // .slice(0, numberShown)
                  .map((prod) =>
                    matches ? (
                      <ProductCard
                        uA={uA}
                        product={prod}
                        key={prod.id}
                        path={props.location.pathname}
                      />
                    ) : (
                      <MobileProductCard
                        uA={uA}
                        product={prod}
                        path={props.location.pathname}
                        key={prod.id}
                      />
                    )
                  )}
              </Grid>
              <div
                style={{
                  position: "fixed",
                  bottom: 20,
                  right: 20,
                  backgroundColor: "white",
                  border: "3px solid",
                  borderColor: theme.palette.secondaryFont.main,
                  borderRadius: 24,
                  padding: 8,
                  color: theme.palette.secondaryFont.main,
                  fontSize: 18,
                  fontWeight: "bold",
                  zIndex: 999,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
              >
                Back To Top
              </div>
            </InfiniteScroll>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              No results found.
            </div>
          )}
        </div>
      )}
    </div>
  );
}
